const bizSdk = require("facebook-nodejs-business-sdk");

const CustomData = bizSdk.CustomData;
const EventRequest = bizSdk.EventRequest;
const UserData = bizSdk.UserData;
const ServerEvent = bizSdk.ServerEvent;

const access_token = process.env.REACT_APP_FB_ACCESS_TOKEN;
const pixel_id = process.env.REACT_APP_PIXEL_ID;

const userAgent = navigator.userAgent;
const url = window.location.href;

const userData = new UserData();

const initPixelUser = (email) => {
  userData.setEmails([email]);
  userData.setClientUserAgent(userAgent);
};

const trackPixelEvent = (eventName, eventDetails) => {
  let current_timestamp = Math.floor(new Date() / 1000);

  const serverEvent = new ServerEvent()
    .setEventName(eventName)
    .setEventTime(current_timestamp)
    .setUserData(userData)
    .setEventSourceUrl(url)
    .setActionSource("website");

  if (eventDetails) {
    const custom_data = new CustomData().setCustomProperties(eventDetails);
    serverEvent.setCustomData(custom_data);
  }

  const eventsData = [serverEvent];
  const eventRequest = new EventRequest(access_token, pixel_id).setEvents(eventsData);

  return eventRequest.execute();
};

export { initPixelUser, trackPixelEvent };
