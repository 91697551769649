import translations from "../utils/translations.json";

let lang;

const t = (text) => {
  if (!lang && text) return text;
  if (!lang && !text) return "";
  if (lang && !text) return "";

  return translations[lang][text] || text;
};

const setLang = (language) => {
  lang = language;
};

export { t, setLang };
