import React from "react";
import { ReactComponent as LoadingComponent } from "../assets/loadingbubbles.svg";

const LoadingBubble = (props) => {
  const { color, height, width } = props;

  const style = {
    fill: color || "red",
    height: Number(height) || 60,
    width: Number(width) || 60,
  };

  return <LoadingComponent height={style.height} width={style.width} fill={style.fill} />;
};

export default LoadingBubble;
