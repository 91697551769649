// @ts-nocheck
import React, { useEffect, useState, useCallback, Fragment, useMemo } from "react";
import LoadingBubble from "../LoadingBubble";
import StepProgressBar from '../StepProgressBar';
import axios from "axios";
import { loadStripe } from "@stripe/stripe-js";
import { Elements, ElementsConsumer } from "@stripe/react-stripe-js";
import GenericFormStripeBox from "./GenericFormStripeBox";
import { has } from "lodash";
import { t, setLang } from "../utils/transfunction";
import { initPixelUser, trackPixelEvent } from "../utils/fbPixelManager";
import { clearFreeTvParam, getFreeTvParam, setFreeTvSessionParam, setFreeTvSessionParamOnLoad } from "../utils/freeTvUtils";

const GenericRegisterForm = () => {
  const [formDetails, setFormDetails] = useState({});
  const [customerDetails, setCustomerDetails] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [voucherCode, setVoucherCode] = useState("");
  const [voucherCodeError, setVoucherCodeError] = useState("");

  const [isRegistrationInProgress, setIsRegistrationInProgress] = useState(false);
  const [isRegistrationCompleted, setIsRegistrationCompleted] = useState(false);
  const [registrationError, setRegistrationError] = useState("");
  const [token, setToken] = useState("");
  const [isLoginMode, setIsLoginMode] = useState(false);

  const [isFreeTvSuccessful, setIsFreeTvSuccessful] = useState(false);
  const [freeTVCode, setFreeTVCode] = useState("");
  const [disableFreeTvInput, setdisableFreeTvInput] = useState(true);
  const [showFreeTvInput, setShowFreeTvInput] = useState(false);
  const [freeTVInputError, setFreeTvInputError] = useState("");


  const url = new URL(window.location.href);
  const utmSource = url.searchParams.get("utm_source");
  const parentUtmSource = url.searchParams.get("parentUtm");
  const parentUtmCampaign = url.searchParams.get("parentCampaign");
  const parentUtmMedium = url.searchParams.get("parentMedium");
  const ironSourceClickId = url.searchParams.get("uniqueClickId");
  const emailRegex = /^\S+@\S+\.\S+$/;

  const resizeObserver = new ResizeObserver(() => {
    // this is responsible to send the iFrame parent a message that generic-form was resized
    sendHeightToParent();
  });

  const getGenericFormDetails = useCallback(async () => {
    let headers;
    const loginData = JSON.parse(localStorage.getItem("loginData"));
    if (loginData !== null) {
      const token = `Bearer ${loginData.token}`;
      headers = {
        Authorization: token,
      };
    }
    const url = `${process.env.REACT_APP_ENSEMBLE_API}/crm/activate/formregister/${utmSource}?lang=en`;
    try {
      setIsLoading(true);
      const response = await axios.get(url, {
        headers,
      });

      if (response.data) {
        setFormDetails({ ...response.data });
        setLang(response.data.language);
      }
    } finally {
      setIsLoading(false);
      resizeObserver.observe(document.getElementById("generic-form"));
    }
    // eslint-disable-next-line
  }, [utmSource]);

  const sendHeightToParent = useCallback(() => {
    const genericForm = document.getElementById("generic-form");
    if (!genericForm) return;

    if (window.location !== window.parent.location) {
      window.parent.postMessage({ contentHeight: genericForm.scrollHeight }, "*");
    } else {
      console.log("No parent detected for iFrame. Not posting height changes.");
    }
  }, []);

  useEffect(() => {
    getGenericFormDetails();
  }, [getGenericFormDetails, sendHeightToParent]);

  useEffect(() => {
    if (!!Object.keys(formDetails).length) {
      const { disableFreeTvInput, showFreeTvInput, freeTvInputValue } = setFreeTvSessionParamOnLoad(formDetails?.showFreeTvTmpCodeInput);
      setdisableFreeTvInput(disableFreeTvInput);
      setShowFreeTvInput(showFreeTvInput);
      setFreeTVCode(freeTvInputValue);
      if (disableFreeTvInput) validateFreeTVCode(freeTvInputValue);
    }
  }, [formDetails]);

  const onFreeTVCodeChange = (e) => {
    setFreeTVCode(e.target.value);
  };

  const onVoucherCodeChange = (e) => {
    setVoucherCode(e.target.value);
  };

  const onEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const onPasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const validateVoucherCode = (e) => {
    const code = e?.target?.value || e;
    if (typeof code !== 'string' || code.trim() === '') setVoucherCodeError(t("GENERIC_FORM_VOUCHER_ERROR"));
    else setVoucherCodeError(null);
  }

  const validateFreeTVCode = (e) => {
    const freeTVCodeToTest = e?.target?.value || e;
    const numbersOnlyRegex = /^\d+$/;
    const isValidFormat = numbersOnlyRegex.test(freeTVCodeToTest);

    let error;
    if (!freeTVCodeToTest?.trim() || !isValidFormat) {
      setFreeTvInputError(t("FREE_TV_MINIMUM_WRONG_FORMAT"));
      error = false;
    } else {
      setFreeTvInputError("");
      error = true;
    }
    return error;
  };

  const validateEmail = (e) => {
    const trimmed = (e?.target?.value !== undefined ? e.target.value : e).trim();
    setEmail(trimmed);
    const isValidEmail = emailRegex.test(trimmed);

    let resp;
    if (!isValidEmail || !trimmed) {
      setEmailError(t("GENERIC_FORM_EMAIL_ERROR"));
      resp = false;
    } else {
      setEmailError("");
      resp = true;
    }
    return resp;
  };

  const validatePassword = (e) => {
    const { needsPassword } = formDetails;
    setPasswordError("");

    if (!needsPassword || isLoginMode) return;

    if (!e.target.value) {
      setPasswordError(t("GENERIC_FORM_PWD_ERROR_EMPTY"));
      return;
    }

    if (needsPassword && e.target.value.length < 8) {
      setPasswordError(t("GENERIC_FORM_PWD_ERROR"));
    }
  };

  const activateVoucherAndLogin = async (email, password, voucherCode) => {
    const { language, redirectionUrl } = formDetails;

    const url = `${process.env.REACT_APP_ENSEMBLE_API}/crm/activate/voucher/${voucherCode}?lang=${language}&utm_source=${utmSource}`;
    const formData = new FormData();
    formData.append("email", email);
    formData.append("password", password);
    let loginToken;

    try {
      const response = await axios({
        method: "post",
        url: url,
        data: formData,
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
      });

      if (response.data) {
        if (response?.data?.customer?.id) {
          loginToken = await loginForVoucher();
        }
      }
    } catch (error) {
      if (error.response?.status === 400) {
        setVoucherCodeError(t("GENERIC_FORM_VOUCHER_FAILED"));
      }
      if (error.response?.status === 404) {
        setVoucherCodeError(t("GENERIC_FORM_VOUCHER_ERROR"));
      } else {
        setVoucherCodeError(t("GENERIC_FORM_VOUCHER_ERROR"));
      }
      setIsRegistrationInProgress(false);
      return;
    }
    const redirectUrl = constructWelcomeUrl(loginToken, redirectionUrl)
    window.top.location = redirectUrl;
  };

  const constructWelcomeUrl = (token, optionalRedirectionUrl) => {
    const { redirectionUrl, welcomePageUrl, automaticLogin } = formDetails;

    let url = `${welcomePageUrl}?redirect=${optionalRedirectionUrl || redirectionUrl}`;
    if (automaticLogin) {
      url = url.concat(`&token=${token}`);
    }
    if (getFreeTvParam()) {
      url = url.concat(`&freeTvSuccessful=${isFreeTvSuccessful}`);
    }
    return url;
  };

  const showEmailOnError = (error) => {
    const errorMessageParts = ["already have an account", "avez déjà un compte"]; // I hate this
    if (errorMessageParts.some((errorMessage) => error.msg.includes(errorMessage))) {
      setIsLoginMode(true);
    }
  };

  const setClientCustomerId = async (tokenHash) => {
    let userId;
    try {
      const { language } = formDetails;
      const urlAccount = `${process.env.REACT_APP_VIALMA_API}/client?lang=${language}`;
      const token = `Bearer ${tokenHash}`;
      const res = await axios.get(urlAccount, {
        headers: {
          Authorization: token,
        },
      });
      userId = res?.data?.userId;
      setCustomerDetails({ ...customerDetails, customer_id: res?.data?.userId, customer_status: "Existing" });
    } catch (error) {
      userId = "Error Fetching User Id";
    }
    setCustomerDetails({ ...customerDetails, customer_id: userId, customer_status: "Existing" });
  };

  const loginForVoucher = async () => {
    const { language } = formDetails;
    let urlLogin = `${process.env.REACT_APP_VIALMA_API}/login_check?lang=${language}`;
    const payload = { _username: encodeURIComponent(email), _password: password };
    let tokenHash;

    try {
      const res = await axios.post(urlLogin, payload);
      if (res.data.token) {
        tokenHash = res.data.token;
      } else {
        if (res.data) {
          setRegistrationError(res.data);
        }
      }
    } catch (error) {
      if (has(error, "response.data.msg")) {
        setRegistrationError(error.response.data.msg);
      }
    }

    return tokenHash;
  };

  const loginExistingUser = async () => {
    const { language } = formDetails;
    let urlLogin = `${process.env.REACT_APP_VIALMA_API}/login_check?lang=${language}`;
    if (parentUtmSource) {
      urlLogin = urlLogin + `&utm_source=${parentUtmSource}`;
    }
    const freeTvParam = getFreeTvParam();
    if (freeTvParam) {
      urlLogin = urlLogin + `&tmpCode=${freeTvParam}`;
    }
    const payload = { _username: email, _password: password };
    let tokenHash;
    await axios
      .post(urlLogin, payload)
      .then((res) => {
        if (res.data.token) {
          tokenHash = res.data.token;
          initPixelUser(email);
          if (isLoginMode) {
            setClientCustomerId(tokenHash);
          }
        } else {
          if (res.data) {
            setRegistrationError(res.data);
          }
        }
        if (freeTvParam) {
          setIsFreeTvSuccessful(true);
        }
      })
      .catch((error) => {
        if (has(error, "response.data.msg")) {
          setRegistrationError(error.response.data.msg);
        }
        setIsFreeTvSuccessful(false);
      });
    return tokenHash;
  };

  const registerNew = async () => {
    const { language } = formDetails;
    let urlCreateFreeAccount = `${process.env.REACT_APP_ENSEMBLE_API}/crm/activate/from_formregister/${utmSource}?lang=${language}`;
    if (parentUtmSource) {
      urlCreateFreeAccount = urlCreateFreeAccount + `&utm_source=${parentUtmSource}`;
    }

    const formData = new FormData();
    formData.append("email", email);
    if (parentUtmSource) formData.append("utm_source", parentUtmSource);
    if (parentUtmCampaign) formData.append("utm_campaign", parentUtmCampaign);
    if (parentUtmMedium) formData.append("utm_medium", parentUtmMedium);
    if (formDetails.needsPassword) formData.append("password", password);

    const freeTvParam = getFreeTvParam();
    if (freeTvParam) formData.append("tmpCode", freeTvParam);

    let tokenHash;

    try {
      const res = await axios({
        method: "post",
        url: urlCreateFreeAccount,
        data: formData,
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
      });

      if (has(res, "data.customer.id")) {
        setCustomerDetails({ ...customerDetails, customer_id: res.data.customer.id, customer_status: "New" });
      }
      if (has(res, "data.token.hash")) {
        // generated password user
        tokenHash = res.data.token.hash || res.data.tokens.access_token.hash;
      }
      if (has(res, "data.tokens.access_token.hash")) {
        // manually entered password user
        tokenHash = res.data.tokens.access_token.hash;
      }
      if (freeTvParam) {
        setIsFreeTvSuccessful(true);
      }
      initPixelUser(email);
      trackPixelEvent("Lead");
    } catch (error) {
      if (has(error, "response.data.msg")) {
        setRegistrationError(error.response.data.msg);
        showEmailOnError(error.response.data);
      } else if (has(error, "response.data.message")) {
        setRegistrationError(error.response.data.message);
        showEmailOnError(error.response.data);
      } else {
        setRegistrationError(error?.response?.data || error?.message || "GENERIC_FORM_GENERIC_ERROR");
      }
      if (freeTvParam) {
        setIsFreeTvSuccessful(false);
      }
    } finally {
      return tokenHash;
    }
  };

  const gaTrackingFunction = (step = "undefined") => {
    try {
      window.dataLayer.push({
        event: "form submit click",
        userEmail: email,
        formMode: isLoginMode ? "login" : "sign up",
        formKey: utmSource,
        formStep: step,
      });
    } catch (error) {
      console.log(error);
    }
  };

  const submitForm = async (event) => {
    event.preventDefault();

    // input check
    const { needsPassword, stripeProductPrice, stripeProductCurrency, requireVoucherCode } = formDetails;

    if (showFreeTvInput && (freeTVInputError || !validateFreeTVCode(freeTVCode))) return;
    if (showFreeTvInput && !freeTVInputError && validateFreeTVCode(freeTVCode)) await setFreeTvSessionParam(freeTVCode);

    if (emailError) return;
    if (!validateEmail(email)) return;

    if (needsPassword) {
      if (passwordError) return;
    }

    setIsRegistrationInProgress(true);

    // login old or create new user
    let tokenHash;

    // Voucher code flow is TOTALLY different, we'll skip all other paths below if voucher is present
    if (requireVoucherCode) {
      activateVoucherAndLogin(email, password, voucherCode)
    } else {
      if (isLoginMode) {
        tokenHash = await loginExistingUser();
      } else {
        tokenHash = await registerNew();
      }
      if (!tokenHash) {
        setIsRegistrationInProgress(false);
        return;
      }
      gaTrackingFunction(1);
      setToken(tokenHash);

      // Deciding to go to payment form or just redirect user
      if (stripeProductPrice && stripeProductCurrency) {
        setIsRegistrationCompleted(true);
      } else {
        trackPixelEvent("Subscribe", {
          value: "Free plan",
          currency: "Free plan",
        });
        window.top.location = constructWelcomeUrl(tokenHash);
        clearFreeTvParam();
      }
      setIsRegistrationInProgress(false);
    }
  };

  const switchOffLoginMode = () => {
    setIsLoginMode(!isLoginMode);
    setPasswordError("");
    setRegistrationError("");
  };

  const stripePromise = useMemo(() => loadStripe(process.env.REACT_APP_STRIPE_KEY, { locale: formDetails.language }), [formDetails.language]);
  let stripeWelcomePageUrl = `${formDetails.welcomePageUrl}?token=${token}&redirect=${formDetails.redirectionUrl}`;
  if (getFreeTvParam()) {
    stripeWelcomePageUrl = stripeWelcomePageUrl.concat(`&freeTvSuccessful=${isFreeTvSuccessful}`);
  }

  const isTestForm = true;

  return (
    <div>
      {isLoading && (
        <div className="loading-box">
          <LoadingBubble color={"#ffffff"} />
        </div>
      )}
      {Boolean(!isLoading && formDetails) && (
        <div className="generic-form" id="generic-form" style={{ backgroundColor: formDetails.backgroundColor || "#f4f4f4", color: formDetails.generalTextColour || "#000000" }}>
          <div
            className={`${isTestForm && "generic-form-content-wrapper"}`}
          >
            {isTestForm && (
              <div className="progress-bar">
                {(Boolean(formDetails?.stripeProductPrice && formDetails?.stripeProductCurrency)) &&
                  <div>
                    <StepProgressBar activeStep={!isRegistrationCompleted ? 0 : 1} stepLabels={[t("REGISTRATION_STEP"), t("PAYMENT_STEP"), t("COMPLETE_STEP")]} color={formDetails.buttonColor} />
                  </div>
                }
              </div>
            )}
            {!isRegistrationCompleted && (
              <div className="register-form">
                {Boolean(formDetails.headerText || (formDetails.stripeProductPrice && formDetails.stripeProductCurrency)) && (
                  <div className="generic-form__top-container">
                    <div className="generic-form__header">{formDetails.headerText}</div>
                  </div>
                )}
                <form>
                  {showFreeTvInput && (
                    <>
                      <div className="register-form__input-title">{t("GENERIC_FORM_FREE_TV_LABEL")}</div>
                      <div className="input">
                        <input
                          value={freeTVCode}
                          placeholder={t("GENERIC_FORM_FREE_INPUT")}
                          type="text"
                          className={`${disableFreeTvInput && "disabled"}`}
                          name="freeTvCode"
                          onChange={onFreeTVCodeChange}
                          onBlur={validateFreeTVCode}
                          data-cy="step-1-free-input"
                          disabled={disableFreeTvInput}
                        />
                      </div>
                      {freeTVInputError && (
                        <div className="register-form__error" data-cy="step-1-free-input-error">
                          {freeTVInputError}
                        </div>
                      )}
                    </>
                  )}
                  {formDetails.requireVoucherCode && (
                    <>
                      <div className="register-form__input-title">{t("GENERIC_FORM_VOUCHER_LABEL")}</div>
                      <div className="input">
                        <input
                          value={voucherCode}
                          placeholder={t("GENERIC_FORM_VOUCHER_INPUT")}
                          type="text"
                          name="voucherCode"
                          onChange={onVoucherCodeChange}
                          onBlur={validateVoucherCode}
                          data-cy="step-1-voucher-input"
                        />
                      </div>
                      {voucherCodeError && (
                        <div className="register-form__error" data-cy="step-1-voucher-input-error">
                          {voucherCodeError}
                        </div>
                      )}
                    </>
                  )}
                  <div className="register-form__input-title">{t("GENERIC_FORM_EMAIL_DESCRIPTION")}</div>
                  <div className="input">
                    <input
                      value={email}
                      placeholder="wmozart@vialma.com"
                      type="text"
                      name="email"
                      onChange={onEmailChange}
                      onBlur={validateEmail}
                      data-cy="step-1-email-input"
                    />
                  </div>
                  {emailError && (
                    <div className="register-form__error" data-cy="step-1-email-input-error">
                      {emailError}
                    </div>
                  )}
                  {Boolean(formDetails.needsPassword || isLoginMode) && (
                    <Fragment>
                      {formDetails.needsPassword && <div className="register-form__input-title">{t("GENERIC_FORM_PASS_DESCRIPTION")}</div>}
                      {isLoginMode && <div className="register-form__input-title">{t("GENERIC_FORM_EXISTING_PASS_DESCRIPTION")}</div>}
                      <div className={passwordError ? "input error-input" : "input"}>
                        <input
                          value={password}
                          type="password"
                          onChange={onPasswordChange}
                          onBlur={validatePassword}
                          data-cy="step-1-password-input"
                        />
                      </div>
                      {isLoginMode && (
                        <a
                          href={`${process.env.REACT_APP_DOMAIN}/forgot-password`}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="forgot-password"
                        >
                          {t("SIGN_IN_FORGOT_PASSWORD")}
                        </a>
                      )}
                    </Fragment>
                  )}
                  {passwordError && (
                    <div className="register-form__error" data-cy="step-1-email-password-error">
                      {passwordError}
                    </div>
                  )}
                  <button
                    onClick={submitForm}
                    className="register-form__submit"
                    style={{ background: formDetails.buttonColor }}
                    disabled={isRegistrationInProgress}
                    data-cy="step-1-submit-button"
                  >
                    {!isRegistrationInProgress && (
                      <span style={{ color: formDetails.buttonTextColor }}>{formDetails.buttonText || t("GENERIC_FORM_CONTINUE_BUTTON")}</span>
                    )}
                    {isRegistrationInProgress && (
                      <div className="loading-icon">
                        <LoadingBubble color={"#ffffff"} />
                      </div>
                    )}
                  </button>
                  {registrationError && (
                    <div className="register-form__error" style={{ paddingTop: "20px" }}>
                      {registrationError}
                    </div>
                  )}
                  {isLoginMode && (
                    <button className="register-form__smalltext" onClick={switchOffLoginMode}>
                      {t("GENERIC_FORM_NEW_ACCOUNT_SWITCH")}
                    </button>
                  )}
                </form>
              </div>
            )}
            {isRegistrationCompleted && (
              <div className="payment-form" data-cy="step-2-registration-form">
                <Elements stripe={stripePromise}>
                  <ElementsConsumer>
                    {({ stripe, elements }) => (
                      <GenericFormStripeBox
                        email={email}
                        price={formDetails.stripeProductPrice}
                        currencyObj={formDetails.stripeProductCurrency}
                        lang={formDetails.language}
                        welcomePageUrl={stripeWelcomePageUrl}
                        billingHash={formDetails.billingPlanHash}
                        parentUtmCampaign={parentUtmCampaign}
                        parentUtmMedium={parentUtmMedium}
                        parentUtmSource={parentUtmSource}
                        ironSourceClickId={ironSourceClickId}
                        stripe={stripe}
                        elements={elements}
                        customerDetails={customerDetails}
                        gaTrackingFunction={gaTrackingFunction}
                        step1ButtonColor={formDetails.buttonColor}
                        step1ButtonTextColor={formDetails.buttonTextColor}
                        paymentButtonColor={formDetails.paymentButtonColor}
                        paymentButtonTextColor={formDetails.paymentButtonTextColor}
                        paymentButtonText={formDetails.paymentButtonText}
                        paymentSummary={formDetails.paymentSummary}
                        savingCopyText={formDetails.savingCopyText}
                        isTestForm={isTestForm}
                      />
                    )}
                  </ElementsConsumer>
                </Elements>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default GenericRegisterForm;
