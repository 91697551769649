import GenericRegisterForm from "./GenericRegisterForm";
import "./styles/style.scss";

function App() {
  return (
    <div className="App">
      <GenericRegisterForm></GenericRegisterForm>
    </div>
  );
}

export default App;
