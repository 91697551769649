export const matchColor = (hexColor) => {
  const r = parseInt(hexColor.slice(1, 3), 16);
  const g = parseInt(hexColor.slice(3, 5), 16);
  const b = parseInt(hexColor.slice(5, 7), 16);

  // Calculate the brightness using the weighted sum of the RGB components
  const brightness = 0.299 * r + 0.587 * g + 0.114 * b;

  // If the brightness is greater than 128, return black; otherwise, return white
  return brightness > 128 ? '#000000' : '#FFFFFF';
}