//FreeTv params are for the FREE TV redirect to vialma.com/fr?tmpCode=xxxx
//The user is expected to login/register and then send their free tv param to the BE via the /login_check API
//This connects their account to Free TV

const FREETV_REDIRECT = "freeTvRedirect";

export const setFreeTvSessionParamOnLoad = (formDetailsShowFreeTvInput) => {
  let resp = { disableFreeTvInput: true, showFreeTvInput: false, freeTvInputValue: null };
  try {
    if (formDetailsShowFreeTvInput) {
      resp = {
        disableFreeTvInput: false,
        showFreeTvInput: true,
        freeTvInputValue: "",
      };
    }
    const url = new URL(window.location.href);
    const freeTvParam = url.searchParams.get("tmpCode");
    
    if (freeTvParam) {
      resp = {
        disableFreeTvInput: true,
        showFreeTvInput: true,
        freeTvInputValue: freeTvParam,
      };
    }
    
  } catch (error) {
    clearFreeTvParam();
  } finally {
    resp?.freeTvInputValue?.toString()?.trim() && window.sessionStorage.setItem(FREETV_REDIRECT, resp.freeTvInputValue);
    return resp;
  }
};

export const setFreeTvSessionParam = async (freeTvParam) => {
  window.sessionStorage.setItem(FREETV_REDIRECT, freeTvParam);
};

export const getFreeTvParam = () => {
  return window?.sessionStorage?.getItem(FREETV_REDIRECT);
};
export const clearFreeTvParam = () => {
  window?.sessionStorage?.removeItem(FREETV_REDIRECT);
};

export const checkFreeTvSuccessfulAndClear = () => {
  if (getFreeTvParam()) {
    clearFreeTvParam();
  }
};
