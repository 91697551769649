// @ts-nocheck
import React, { Fragment, useEffect, useState } from "react";
import LoadingBubble from "../LoadingBubble";
import { Mixpanel } from "../utils/mixpanel";
import axios from "axios";
import { CardNumberElement, CardExpiryElement, CardCvcElement } from "@stripe/react-stripe-js";
import paymentStripe from "../assets/payment_stripe.svg";
import greenTick from "../assets/greentick.svg";
import alert from "../assets/alert.svg";
import lock from "../assets/lock.svg";
import { t } from "../utils/transfunction";
import { trackPixelEvent } from "../utils/fbPixelManager";
import { checkFreeTvSuccessfulAndClear } from "../utils/freeTvUtils";
import cvcIcon from "../cvc_icon.svg";

const style = {
  base: {
    borderRadius: "10px",
    height: "48px",
    fontFamily: "Roboto, sans-serif",
    fontSmoothing: "antialiased",
    fontSize: "16px",
    "::placeholder": {
      color: "#BBBFC7",
    },
    ":focus": {
      borderColor: "#BBBFC7",
    },
  },
  invalid: {
    color: "#DD4E52",
  },
};

const GenericFormStripeBox = ({
  stripe,
  elements,
  email,
  currencyObj,
  lang,
  welcomePageUrl,
  billingHash,
  parentUtmCampaign,
  parentUtmMedium,
  parentUtmSource,
  ironSourceClickId,
  price,
  customerDetails,
  gaTrackingFunction,
  paymentButtonText,
  paymentButtonColor,
  paymentButtonTextColor,
  step1ButtonColor,
  step1ButtonTextColor,
  paymentSummary,
  savingCopyText,
  isTestForm,
}) => {
  const [name, setName] = useState(false);
  const [isCardComplete, setIsCardComplete] = useState(false);
  const [isExpiryComplete, setIsExpiryComplete] = useState(false);
  const [isCvcComplete, setIsCvcComplete] = useState(false);
  const [cardError, setCardError] = useState("");
  const [expiryError, setExpiryError] = useState("");
  const [cvcError, setCvcError] = useState("");
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [paymentSuccess, setPaymentSuccess] = useState(false);
  const [scriptAdded, setScriptAdded] = useState(false);
  const [redirectTimer, setRedirectTimer] = useState(5);
  const isRakutenConversion = parentUtmSource === "rakuten";

  const url = new URL(window.location.href);
  const formKey = url.searchParams.get("utm_source");
  const isDonation = formKey === "warchild_concert_ticket_£" || formKey === "warchild_concert_ticket_€";
  const buttonText = paymentButtonText ? paymentButtonText : isDonation ? t("GENERIC_FORM_DONATE_BUTTON") : t("GENERIC_FORM_PAY_BUTTON");
  const buttonColor = paymentButtonColor ?? step1ButtonColor;
  const buttonTextColor = paymentButtonTextColor ?? step1ButtonTextColor;

  useEffect(() => {
    if (scriptAdded) {
      if (redirectTimer === 0) {
        setRedirectTimer(null);
        checkFreeTvSuccessfulAndClear();
        window.top.location = `${welcomePageUrl}`;
      }

      if (!redirectTimer) return;

      const intervalId = setInterval(() => {
        setRedirectTimer(redirectTimer - 1);
      }, 1000);

      return () => clearInterval(intervalId);
    }
  }, [scriptAdded, redirectTimer, welcomePageUrl]);

  useEffect(() => {
    if (paymentSuccess) {
      checkFreeTvSuccessfulAndClear();
      if (isRakutenConversion) {
        const script = document.createElement("script");
        script.type = "text/javascript";
        script.src = "rakuten.js";
        script.async = true;
        document.body.appendChild(script);
        setScriptAdded(true);
      } else {
        window.top.location = `${welcomePageUrl}`;
      }
    }
  }, [welcomePageUrl, paymentSuccess, isRakutenConversion]);

  const pushRakutenToDatalayer = (paymentTrackingObject) => {
    window.rm_trans = paymentTrackingObject;
  };

  const handleSubmit = (ev) => {
    ev.preventDefault();
    setLoading(true);
    Mixpanel.track("Checkout - clicked CTA to Pay");

    const cardElement = elements.getElement(CardNumberElement);

    const { customer_id, customer_status } = customerDetails;
    const orderIDValue = `RAK${Date.now()}`;
    let paymentTrackObject = {
      affiliateConfig: {
        ranMID: process.env.REACT_APP_RAKUTEN_MID,
        discountType: "item",
        includeStatus: "false",
        taxRate: 20,
        removeTaxFromDiscount: true,
      },
      orderid: orderIDValue,
      currency: "Not Yet",
      customerStatus: customer_status,
      customerID: customer_id,
      discountCode: "",
      discountAmount: 0,
      lineitems: [
        {
          quantity: 1,
          unitPrice: "Not Yet",
          unitPriceLessTax: "Not Yet",
          SKU: "Not Yet",
          productName: "Not Yet",
        },
      ],
    };
    if (stripe) {
      stripe.createToken(cardElement).then(async (payload) => {
        if (!payload.error) {
          const url = new URL(window.location.href);
          const formKey = url.searchParams.get("utm_source");

          const encodedEmail = encodeURIComponent(email);
          const encodedFormKey = encodeURIComponent(formKey);
          let urlCreateTransaction = `${process.env.REACT_APP_ENSEMBLE_API}/crm/public/stripe/charge/intents/${billingHash}/${currencyObj.id}?lang=${lang}&email=${encodedEmail}&form_key=${encodedFormKey}`;
          if (isRakutenConversion) {
            urlCreateTransaction = urlCreateTransaction.concat(`&rakutenId=${orderIDValue}`);
          }

          const formData = new FormData();
          if (parentUtmSource) formData.append("utm_source", parentUtmSource);
          if (parentUtmCampaign) formData.append("utm_campaign", parentUtmCampaign);
          if (parentUtmMedium) formData.append("utm_medium", parentUtmMedium);
          if (ironSourceClickId) formData.append("ironSourceUniqueClickId", ironSourceClickId);
          if (orderIDValue && isRakutenConversion) formData.append("rakutenId", orderIDValue);
          await axios
            .post(urlCreateTransaction, formData)
            .then((resp) => {
              const tokenBackendStripe = resp.data.message;

              stripe
                .confirmCardPayment(tokenBackendStripe, {
                  payment_method: {
                    card: cardElement,
                  },
                })
                .then((payload) => {
                  if (!payload.error) {
                    try {
                      trackPixelEvent("Subscribe", {
                        value: price,
                        currency: currencyObj.code,
                      });
                    } finally {
                      if (isRakutenConversion) {
                        paymentTrackObject.currency = payload?.paymentIntent?.currency?.toUpperCase?.();
                        paymentTrackObject.lineitems[0].productName = payload?.paymentIntent?.description;
                        paymentTrackObject.lineitems[0].SKU = payload?.paymentIntent?.description;
                        paymentTrackObject.lineitems[0].unitPrice = payload?.paymentIntent?.amount / 100;
                        paymentTrackObject.lineitems[0].unitPriceLessTax = payload?.paymentIntent?.amount / 100 / 1.2;
                        paymentTrackObject.lineitems[0].optionalData = {};
                        paymentTrackObject.lineitems[0].optionalData.cat = "subscription";
                        pushRakutenToDatalayer(paymentTrackObject);
                      }
                      setPaymentSuccess(true);
                      gaTrackingFunction(2);
                    }
                  } else {
                    setErrorMsg(payload);
                  }
                });
            })
            .catch((error) => {
              const errorMsg = { error: error?.response?.message || error?.response?.data?.message || t("GENERIC_FORM_GENERIC_ERROR") };
              setErrorMsg(errorMsg);
              window.scrollTo(0, 0);
            });
        } else {
          setErrorMsg(payload);
        }
      });
    } else {
      setLoading(false);
      setErrorMsg(t("GENERIC_FORM_GENERIC_ERROR"));
      window.scrollTo(0, 0);
    }
  };

  const setErrorMsg = (payload) => {
    setError(payload.error?.message ?? payload.error);
    setLoading(false);
  };

  const handleName = (event) => {
    setName(event.target.value);
  };

  const handleFormChange = (event, setErrorFunction, setIsCompleteFunction) => {
    if (event.error) {
      setErrorFunction(event.error);
    } else if (event.complete) {
      setErrorFunction("");
    }
    setIsCompleteFunction(event.complete);
  };

  const handleCardChange = (event) => {
    handleFormChange(event, setCardError, setIsCardComplete);
  };

  const handleExpiryChange = (event) => {
    handleFormChange(event, setExpiryError, setIsExpiryComplete);
  };

  const handleCvcChange = (event) => {
    handleFormChange(event, setCvcError, setIsCvcComplete);
  };

  const showGeneralErrorBox = () => {
    return (
      error &&
      error.code !== "incomplete_name" &&
      error.code !== "incomplete_number" &&
      error.code !== "incomplete_expiry" &&
      error.code !== "incomplete_cvc"
    );
  };

  const getSubscribeButtonClass = () => {
    let className = `pay-button ${isTestForm && "transparent"}`;
    if (loading || cardError || expiryError || cvcError || !isCardComplete || !isExpiryComplete || !isCvcComplete || !name) {
      className += " disabled";
    }
    return className;
  };

  const getPriceStr = () => {
    let resp;
    try {
      resp = parseFloat(price).toFixed(2);
    } catch (error) {
      resp = price;
    }
    return resp;
  };
  return (
    <Fragment>
      <div className="generic-form__top-container">
        <div className="generic-form__header">{t("GENERIC_FORM_PAYMENT_TITLE")}</div>
        {isTestForm && (
          <div
            className="order-summary"
            style={{
              borderColor: paymentButtonColor,
            }}
          >
            {getPriceStr() && (
              <p>
                {t("YOU_PAY")}&nbsp;:&nbsp;
                <span className="bold">
                  {currencyObj?.code !== "EUR" ? "£" : ""} {getPriceStr()} {currencyObj?.code === "EUR" ? "€" : ""}
                </span>
              </p>
            )}
            {savingCopyText && <p>{savingCopyText}</p>}
            <p>{t("UNSUBSCRIBE_COPY")}</p>
          </div>
        )}
      </div>
      <div className="payment-form__stripe-box">
        <form onSubmit={handleSubmit}>
          <div className="payment-form__stripe-box__row">
            <div className="label">{t("GIFT_STRIPE_PAYMENT_NAME")}</div>
            <input
              name="name"
              type="text"
              placeholder="Wolfgang Amadeus Mozart"
              onChange={handleName}
              onFocus={() => Mixpanel.track("Checkout - entered Name")}
              className={`${!name && error && error.code === "incomplete_name" ? "name-error" : ""}`}
              data-cy="payee_name_input"
            />
            {name && <img className="green-tick" src={greenTick} alt="" />}
            {!name && error && error.code === "incomplete_name" && <div className="error-msg">{error.message}</div>}
          </div>
          <div className="payment-form__stripe-box__bigrow">
            <div className="payment-form__stripe-box__bigrow__card-container">
              <div className="label">{t("GIFT_STRIPE_PAYMENT_CARD_NUMBER")}</div>
              <div className={cardError ? "input-white error" : "input-white"}>
                <CardNumberElement
                  placeholder="2701 1756 2701 1756"
                  onChange={handleCardChange}
                  onFocus={() => Mixpanel.track("Checkout - entered Card Number")}
                  options={{ style: style }}
                  id="cardnumber_input"
                />
                {isCardComplete && <img className="green-tick" src={greenTick} alt="" />}
              </div>
              {cardError && (
                <div className="error-msg" data-cy="step-2-card-number-error">
                  {cardError.message}
                </div>
              )}
            </div>
            <div className="expiry-cvc-container">
              <div className="payment-form__stripe-box__bigrow__expiry-container">
                <div className="label">{t("GIFT_STRIPE_PAYMENT_CARD_EXPIRY")}</div>
                <div className={expiryError ? "input-white error" : "input-white"}>
                  <CardExpiryElement
                    onChange={handleExpiryChange}
                    onFocus={() => Mixpanel.track("Checkout - entered Expiry Date - Month/Year")}
                    options={{ style: style }}
                    id="cardexpiry_input"
                  />
                  {isExpiryComplete && <img className="green-tick" src={greenTick} alt="" />}
                </div>
                {expiryError && (
                  <div className="error-msg" data-cy="step-2-card-expiry-error">
                    {expiryError.message}
                  </div>
                )}
              </div>
              <div className="payment-form__stripe-box__bigrow__security-container">
                <div className="label">
                  {t("GIFT_STRIPE_PAYMENT_SECURITY_CODE")}
                  {isTestForm && <img className="cvc_icon" src={cvcIcon} alt="cvc" />}
                </div>
                <div className="cvc-row">
                  <div className={cvcError ? "input-white cvc error" : "input-white cvc"}>
                    <CardCvcElement
                      placeholder="000"
                      onChange={handleCvcChange}
                      onFocus={() => Mixpanel.track("Checkout - entered Security Code")}
                      options={{ style: style }}
                      id="cardcvc_input"
                    />
                    {isCvcComplete && <img className="green-tick" src={greenTick} alt="" />}
                  </div>
                </div>
                {cvcError && (
                  <div className="error-msg" data-cy="step-2-card-cvc-error">
                    {cvcError.message}
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="info-payment">
            <div className="info-payment__left">
              <img src={lock} alt="" />
              {t("PAYMENT_SECURE")}
            </div>
            <div className="info-payment__right">
              <img src={paymentStripe} alt="" />
            </div>
          </div>

          <button
            className={getSubscribeButtonClass()}
            style={{ background: buttonColor, color: buttonTextColor }}
            disabled={loading}
            data-cy="step-2-payment-button"
          >
            {!loading && <span style={{ color: buttonTextColor }}>{buttonText}</span>}
            {loading && !scriptAdded && <LoadingBubble color={"#ffffff"} />}
            {paymentSuccess && scriptAdded && (
              <span style={{ color: buttonTextColor }}>
                {t("REDIRECTING_IN")}
                {redirectTimer}
              </span>
            )}
          </button>
          {isTestForm && <p className="unsubscribe-copy">{t("UNSUBSCRIBE_COPY")}</p>}
        </form>
        {showGeneralErrorBox() && (
          <div className="box-error" data-cy="step-2-stripe-error">
            <img src={alert} className="alert" alt="" />
            <div className="text-error">
              <div className="head-error">{t("PAYMENT_VALIDATION_TITLE")}</div>
              <div className="sub-error">
                {error && typeof error === "string" ? (
                  error
                ) : (
                  <>
                    {t("PAYMENT_VALIDATION_COPY")}
                    <a href={`mailto:${t("PAYMENT_VALIDATION_CONTACT")}`}>{t("PAYMENT_VALIDATION_CONTACT")}</a>
                  </>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    </Fragment>
  );
};

export default GenericFormStripeBox;
