import React from 'react';
import { ReactComponent as DoneStep } from "../assets/step_done.svg";
import { ReactComponent as NotDoneStep } from "../assets/step_not_done.svg";
import { ReactComponent as StepConnectorSVG } from "../assets/step_connector.svg";
import { matchColor } from '../utils/colorCalculator';


const Connector = ({ active, color }) => {
  return (
    <StepConnectorSVG className={`connector${active ? ' active' : ''}`} style={{ stroke: color }} />
  );
};

const StepWithText = ({ active, text, color }) => {
  return (
    <div className="stepContainer">
      {active ? <DoneStep style={{ stroke: matchColor(color || "#dd4e52") || "#dd4e52", fill: color }} /> : <NotDoneStep />}
      <div className={`text${active ? ' active' : ''}`} style={{ color : active ? (color ? '#000000' : "#dd4e52") : '#c7c7c7'}}>{text}</div>
    </div>
  );
};

const StepProgressBar = ({ activeStep, stepLabels, color }) => {
  return (
    activeStep === 0 ? (
      <div className="svg-progress-bar">
        <StepWithText active text={stepLabels[0]} color={color} />
        <Connector active color={color} />
        <StepWithText text={stepLabels[1]} />
        <Connector />
        <StepWithText text={stepLabels[2]} />
      </div>
    ) : activeStep === 1 ? (
      <div className="svg-progress-bar">
        <StepWithText active text={stepLabels[0]} color={color} />
        <Connector active color={color} />
        <StepWithText active text={stepLabels[1]} color={color} />
        <Connector active color={color} />
        <StepWithText text={stepLabels[2]} />
      </div>
    ) : null
  );
};

export default StepProgressBar;


