import mixpanel from "mixpanel-browser";

const mp_session_config = {
  //one hour in milliseconds
  timeout: 3600000,

  //safe client-side function for generating session_id
  //from: https://stackoverflow.com/a/105074
  get_Session_id: function () {
    function s4() {
      return Math.floor((1 + Math.random()) * 0x10000)
        .toString(16)
        .substring(1);
    }
    return s4() + s4() + "-" + s4() + "-" + s4() + "-" + s4() + "-" + s4() + s4() + s4();
  },

  //set a new session id
  set_Session_id: function () {
    mixpanel.register({
      "session ID": mp_session_config.get_Session_id(),
    });
  },

  //check for a new session id
  check_Session_id: function () {
    // check #1 do they have a session already?
    if (!mixpanel.get_property("last event time")) {
      mp_session_config.set_Session_id();
    }

    if (!mixpanel.get_property("session ID")) {
      mp_session_config.set_Session_id();
    }

    //check #2 did the last session exceed the timeout?
    if (Date.now() - mixpanel.get_property("last event time") > mp_session_config.timeout) {
      mp_session_config.set_Session_id();
    }
  },
};

mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN, {
  loaded: (mixpanel) => {
    //Source: https://help.mixpanel.com/hc/en-us/articles/360021959052-Client-Side-Session-in-Mixpanel
    mp_session_config.check_Session_id();
    const originalTrack = mixpanel.track;
    mixpanel.track = function () {
      mp_session_config.check_Session_id();
      mixpanel.register({ "last event time": Date.now() });
      originalTrack.apply(mixpanel, arguments);
    };

    const distinctId = mixpanel.get_distinct_id();
    localStorage.setItem("distinctId", distinctId);
  },
});

let actions = {
  identify: (id) => {
    mixpanel.identify(id);
  },
  alias: (id) => {
    mixpanel.alias(id);
  },
  track: (name, props) => {
    mixpanel.track(name, props);
  },
  people: {
    set: (props) => {
      mixpanel.people.set(props);
    },
    set_once: (props) => {
      mixpanel.people.set_once(props);
    },
  },
  reset: () => {
    mixpanel.reset();
  },
  register: (props) => {
    mixpanel.register(props);
  },
};

export let Mixpanel = actions;
